import { IIntroMetas, IIntrosSorting, ILegacySoundConfig, IntroCardConfig, IUserMetas } from '@interfaces';

export const HOST = 'https://www.docsnyderspage.com';
export const LOCALSTORAGE_ID_KEY = 'DSPSRID';
export const LOCALSTORAGE_DARKMODE_KEY = 'DSPDARKMODE';

export const SESSION_STORAGE_SOUND_KEY = 'DSP_ICRAP_SOUND_WARNING';

export const DATE_ATTR_KEYS = ['createdAt', 'updatedAt', 'publishedAt'];
export const DSP_DATE_FORMATS: { [key: string]: string } = {
  FULL: 'D MMMM, YYYY',
  MONTH_AND_YEAR: 'MMMM YYYY',
  DAY_AND_MONTH: 'D MMMM',
  MONTH: 'MMMM',
  YEAR: 'YYYY',
};

export const DSP_COLORS = {
  BLACK: '#000',
  BLUE: '#009ee0',
  GRAY_DARK: '#666',
  GRAY_LIGHT: '#999',
  GRAY_LIGHTER: '#ccc',
  ORANGE: '#ff8a00',
  WHITE: '#fff',
};

export const MAX_NAME_LENGTH = 15;
export const MIN_INTRO_YEAR = 1980;
export const DEFAULT_INTRO_METAS: IIntroMetas = {
  score: 0,
  rating: 0,
  votes: 0,
  clicks: 0,
  publishedAt: null,
  releaseDate: { year: null, month: null, day: null },
  missingInfo: false,
  productionNotes: null,
  tweetId: null,
  tootId: null,
  apiVersion: 6,
};

export const DEFAULT_USER_METAS: IUserMetas = {
  crt: false,
  rating: true,
  timestamp: 0,
  contact: null,
  darkmode: null,
  fw: false,
};

export const DEFAULT_LEGACY_SOUND_CONFIG: ILegacySoundConfig = {
  file: null,
  subTune: 0,
  speed: 1.0,
  mp3: null,
  sid: null,
};

export const WEBSID_SCRIPTS = ['scriptprocessor_player.js', 'backend_tinysid-201806.js'];

export const BROWSER_MIN_VERSIONS: { [name: string]: number } = {
  chrome: 85,
  safari: 14,
  opera: 71,
  firefox: 79,
  ie: 999,
};

export const DEFAULT_INTRO_CARD_CONFIG: IntroCardConfig = {
  mobile: false,
  releaseDate: 'full',
  tracking: null,
};

export const DEFAULT_INTROS_SORTING: IIntrosSorting = {
  key: 'publishedAt',
  direction: -1,
  cssClass: 'publishedAt desc',
};

export const I_CRAP_WARNING_MESSAGE =
  "It seems like you're using Safari. Please ensure media autoplay is enabled for this website, otherwise you won't get any sound.";
export const I_CRAP_WARNING_MESSAGE_SHORT =
  'It seems like media autoplay has not been enabled for this website. You will not get any sound.';
